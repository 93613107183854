// Import resources
// VARIABLES
// FUNCTIONS
// HANDLE DELIVERY PRICE BASED ON KM
export const handleDeliveryPrice = (priceList, kmInput) => {
  // If km is empty return
  const kmInputFormat = parseInt(kmInput);
  if (!kmInputFormat || typeof priceList != "object") return 0;
  // Define result
  let result;
  // If priceList > 0
  if (priceList?.length > 0) {
    // Loop priceList and return price
    priceList?.forEach((item) => {
      if (kmInputFormat >= item?.fromKM && kmInputFormat <= item?.toKM) {
        result = {
          motor: item?.motorPrice,
          car: item?.carPrice,
          km: `${kmInputFormat}km`,
        };
        //console.log("Debug fxn: ", result);
      }
    }); // close loop
  } else {
    result = 0;
  } // close if priceList
  // Return result
  return result;
}; // close fxn
