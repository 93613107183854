// Import resources
import React, { useState } from "react";
import { useField, ErrorMessage } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// Component
function CustomPasswordField({
  divClass,
  label,
  isGroup,
  isGroupText,
  ...props
}) {
  // Define use field hook
  const [field, meta] = useField(props);

  // Define state
  const [showPassword, setShowPassword] = useState(false);

  // Toggle password visibility
  const togglePassword = () => {
    // Inverse the current boolean state
    setShowPassword(!showPassword);
  };

  // Return
  return (
    <div className="input-group mb-3 w-100">
      {/** Input */}
      <input
        type={showPassword ? "text" : "password"}
        className={`form-control shadow-none ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}
      />

      {/** Toggle password button */}
      <button
        className="btn btn-light border border-2 text-dark"
        type="button"
        onClick={togglePassword}
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </button>

      {/** Error message */}
      <div className="w-100">
        <ErrorMessage
          name={field.name}
          component="div"
          className="text-danger small"
        />
      </div>
    </div>
  );
}

// Export
export default CustomPasswordField;
