// Import resources
import React from "react";
import { useSelector } from "react-redux";

// Import custom files
import PageSection from "./PageSection";
import { selectGeneralDetails } from "../redux/slices/webDetailsSlice";

// Component
function Footer2() {
  // Select general details
  const generalDetails = useSelector(selectGeneralDetails);

  // Return
  return (
    // Copyright section
    <PageSection sectionClass="footer-2 text-white text-center">
      <div className="container">
        <p>
          &copy; {new Date().getFullYear()} {generalDetails?.copyrightText}
        </p>
      </div>
    </PageSection>
  );
}

// Export
export default Footer2;
