// Import resources
import React from "react";

// Component
function PageCol({ children, colClass, ...rest }) {
  // Return
  return (
    <div className={colClass} {...rest}>
      {children}
    </div>
  );
}

// Export
export default PageCol;
