// import resources
import React from "react";

// Import custom files
import Footer1 from "./Footer1";
import Footer2 from "./Footer2";
import Header from "./Header";
import TopMenu from "./TopMenu";
import ScrollToTopRouter from "../components/ScrollToTopRouter";

// Component
function PageContent({ children }) {
  // Return
  return (
    <div className="content-container">
      {/** Top components */}
      <TopMenu />
      <Header />
      <ScrollToTopRouter />

      {/** Individual page content goes here */}
      {children}

      <footer className="footer-content">
        <Footer1 />
        <Footer2 />
      </footer>
    </div>
  );
}

// Export
export default PageContent;
