// Import resources
import React from "react";
import { NavLink } from "react-router-dom";

// Import custom files
import { useSelector } from "react-redux";
import { selectGeneralDetails } from "../redux/slices/webDetailsSlice";
import PageCol from "./PageCol";
import PageSection from "./PageSection";

// Component
function Footer1() {
  // Select general details
  const generalDetails = useSelector(selectGeneralDetails);

  // Return
  return (
    <PageSection sectionClass="footer-1 text-white">
      {/** COMPANY */}
      <PageCol colClass="col-md-3 mt-4">
        <h5>Company</h5>
        <hr />
        <p>{generalDetails?.briefHistory}</p>
      </PageCol>

      {/** QUICK LINKS */}
      <PageCol colClass="col-md-3 mt-4">
        <h5>Quick Links</h5>
        <hr />
        {/** Loop */}
        {generalDetails?.footerQuickLinks.map((item, index) => (
          <div key={index}>
            <NavLink to={`/${item.route}`} activeClassName="active">
              {item.name}
            </NavLink>
          </div>
        ))}
      </PageCol>

      {/** SOCIAL LINKS */}
      <PageCol colClass="col-md-3 mt-4">
        <h5>Social Links</h5>
        <hr />
        {/** Loop */}
        {generalDetails?.socialLinks.map((item, index) => (
          <div key={index}>
            <a href={item.url} target="_blank" rel="noreferrer">
              {item.title}
            </a>
          </div>
        ))}
      </PageCol>

      {/** DOWNLOAD APP */}
      <PageCol colClass="col-md-3 mt-4">
        <h5>Download App</h5>
        <hr />
        {/** Loop */}
        {generalDetails?.appLinks.map((item, index) => (
          <div key={index} className="app-badge mb-3">
            <a href={item.url} target="_blank" rel="noreferrer">
              <img src={item.badge} className="img-fluid" alt={item.slug} />
            </a>
          </div>
        ))}
      </PageCol>
    </PageSection>
  );
}

// Export
export default Footer1;
