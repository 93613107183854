// Import resources
import React from "react";
import { Spinner } from "react-bootstrap";

// Component
function CustomSpinner({ animation, ...rest }) {
  // Return
  return (
    <Spinner animation={animation} role="status" {...rest}>
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

// Export
export default CustomSpinner;
