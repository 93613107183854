// Import resources
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";

// Import custom files
import CustomTextField from "../components/CustomTextField";
import CustomButton from "../components/CustomButton";
import { fireAuth } from "../config/firebase";
import CustomSpinner from "./CustomSpinner";
import { redirectURL } from "../config/data";
import CustomPasswordField from "./CustomPasswordField";

// Component
function FormLogin() {
  // Define alert
  const alert = useAlert();

  // Define initial values
  const initialValues = {
    emailAddr: "",
    pass: "",
  };

  // Validation
  const validate = Yup.object({
    emailAddr: Yup.string().required("Required"),
    pass: Yup.string().required("Required"),
  });

  // Define submit function
  const onSubmit = async (values, actions) => {
    // Alert values
    // alert(JSON.stringify(values, null, 2));

    // Signup user
    // Provide email and pass
    await fireAuth
      .signInWithEmailAndPassword(values.emailAddr, values.pass)
      .then((response) => {
        // Debug
        //console.log("loginResponse: ", response.user.refreshToken);

        // If user email is verified
        if (response?.user?.emailVerified) {
          // Set sessionStorage
          sessionStorage.setItem("authKey", response.user.refreshToken);
          // Alert success
          alert.success("Login Successful");
        } else {
          // Alert error
          //alert.error("Unverified Account. Please visit the help center");
          // Define config
          const actionConfig = {
            url: `${redirectURL}/login`,
            handleCodeInApp: true,
          };

          // Send verification email
          response.user
            .sendEmailVerification(actionConfig)
            .then(() => {
              // Alert warning
              alert.info(
                "Unverified account. We sent you an email to verify your account."
              );

              // Reset form
              actions.resetForm();
            })
            .catch((err) => {
              // Alert error
              alert.error(err.message);
              console.log(err.message);
            });
        }
      })
      .catch((error) => {
        // Show error
        alert.error(error.message);
      });

    // Set submitting to false
    actions.setSubmitting(false);
  };

  // Return
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validate}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form autoComplete="off">
          {/* {console.log('Login form:', formik.values)} */}
          {/** Email */}
          <CustomTextField
            divClass="mb-3"
            name="emailAddr"
            type="text"
            placeholder="Email Address"
          />

          {/** Password */}
          <CustomPasswordField name="pass" placeholder="Password" />

          {/** Forgot password link */}
          <div className="text-center mt-3">
            <Link to="/password-recovery" className="auth-link">
              Forgot Password?
            </Link>
          </div>

          {/** Button */}
          <div className="d-grid mt-3">
            <CustomButton
              type="submit"
              size="lg"
              disabled={!formik.isValid || formik.isSubmitting}
            >
              Login
              {formik.isSubmitting && (
                <CustomSpinner className="ms-2" animation="border" size="sm" />
              )}
            </CustomButton>
          </div>

          {/** Signup link */}
          <div className="text-center pt-3">
            <Link to="/signup" className="auth-link text-center mt-2">
              Signup
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
}

// Export
export default FormLogin;
