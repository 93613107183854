// Import resources
import React from "react";
import { Button } from "react-bootstrap";

// Component
function CustomButton({ type, variant, children, ...rest }) {
  // Return
  return (
    <Button type={type} variant={variant} {...rest}>
      {children}
    </Button>
  );
}

// Export
export default CustomButton;
