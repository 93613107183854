// Import resources
import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  pageLoadingState: false,
  donateProjects: null,
  primePackages: null,
  generalDetails: null,
  pageHome: null,
  pageBookDelivery: null,
  pageAboutUs: null,
  pageBenefitsRefund: null,
  pageBookingPrime: null,
  pageDonateAbout: null,
  pageDonateGuidelines: null,
  pageDonateTerms: null,
  pageFAQs: null,
  pageLegal: null,
  pagePrimeTerms: null,
  pagePrivacyPolicy: null,
  pagePurchasingPrime: null,
  pageTermsConditions: null,
};

// Create slice
export const webDetailsSlice = createSlice({
  name: "webDetails",
  initialState,
  reducers: {
    setPageLoadingState: (state, action) => {
      state.pageLoadingState = action.payload;
    },
    setGeneralDetails: (state, action) => {
      state.generalDetails = action.payload;
    },
    setPageHome: (state, action) => {
      state.pageHome = action.payload;
    },
    setPageBookDelivery: (state, action) => {
      state.pageBookDelivery = action.payload;
    },
    setDonateProjects: (state, action) => {
      state.donateProjects = action.payload;
    },
    setPrimePackages: (state, action) => {
      state.primePackages = action.payload;
    },
    setPageAboutUs: (state, action) => {
      state.pageAboutUs = action.payload;
    },
    setPageBenefitsRefund: (state, action) => {
      state.pageBenefitsRefund = action.payload;
    },
    setPageBookingPrime: (state, action) => {
      state.pageBookingPrime = action.payload;
    },
    setPageDonateAbout: (state, action) => {
      state.pageDonateAbout = action.payload;
    },
    setPageDonateGuidelines: (state, action) => {
      state.pageDonateGuidelines = action.payload;
    },
    setPageDonateTerms: (state, action) => {
      state.pageDonateTerms = action.payload;
    },
    setPageFAQs: (state, action) => {
      state.pageFAQs = action.payload;
    },
    setPageLegal: (state, action) => {
      state.pageLegal = action.payload;
    },
    setPagePrimeTerms: (state, action) => {
      state.pagePrimeTerms = action.payload;
    },
    setPagePrivacyPolicy: (state, action) => {
      state.pagePrivacyPolicy = action.payload;
    },
    setPagePurchasingPrime: (state, action) => {
      state.pagePurchasingPrime = action.payload;
    },
    setPageTermsConditions: (state, action) => {
      state.pageTermsConditions = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
// Set action creators
export const {
  setPageLoadingState,
  setGeneralDetails,
  setPageHome,
  setPageBookDelivery,
  setDonateProjects,
  setPrimePackages,
  setPageAboutUs,
  setPageBenefitsRefund,
  setPageBookingPrime,
  setPageDonateAbout,
  setPageDonateGuidelines,
  setPageDonateTerms,
  setPageFAQs,
  setPageLegal,
  setPagePrimeTerms,
  setPagePrivacyPolicy,
  setPagePurchasingPrime,
  setPageTermsConditions,
} = webDetailsSlice.actions;

// Set selectors
export const selectPageLoadingState = (state) =>
  state.webDetails.pageLoadingState;
export const selectGeneralDetails = (state) => state.webDetails.generalDetails;
export const selectPageHome = (state) => state.webDetails.pageHome;
export const selectPageBookDelivery = (state) =>
  state.webDetails.pageBookDelivery;
export const selectDonateProjects = (state) => state.webDetails.donateProjects;
export const selectPrimePackages = (state) => state.webDetails.primePackages;
export const selectPageAboutUs = (state) => state.webDetails.pageAboutUs;
export const selectPageBenefitsRefund = (state) =>
  state.webDetails.pageBenefitsRefund;
export const selectPageBookingPrime = (state) =>
  state.webDetails.pageBookingPrime;
export const selectPageDonateAbout = (state) =>
  state.webDetails.pageDonateAbout;
export const selectPageDonateGuidelines = (state) =>
  state.webDetails.pageDonateGuidelines;
export const selectPageDonateTerms = (state) =>
  state.webDetails.pageDonateTerms;
export const selectPageFAQs = (state) => state.webDetails.pageFAQs;
export const selectPageLegal = (state) => state.webDetails.pageLegal;
export const selectPagePrimeTerms = (state) => state.webDetails.pagePrimeTerms;
export const selectPagePrivacyPolicy = (state) =>
  state.webDetails.pagePrivacyPolicy;
export const selectPagePurchasingPrime = (state) =>
  state.webDetails.pagePurchasingPrime;
export const selectPageTermsConditions = (state) =>
  state.webDetails.pageTermsConditions;

// Export
export default webDetailsSlice.reducer;
