// Import resources
import React from "react";
import { FaTruck } from "react-icons/fa";

// Define currency symbol
const currSymbol = "GH₵";

// Define intl number format
const intl = new Intl.NumberFormat();

// Base URL
const baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEV
    : process.env.REACT_APP_BASE_URL_PROD;

// Google Map API key
const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAPS_APIKEY;

// Redirect URL
const redirectURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_REDIRECT_URL_DEV
    : process.env.REACT_APP_REDIRECT_URL_PROD;

// Paystack key
const paystackKey =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_PAYSTACK_PKTEST
    : process.env.REACT_APP_PAYSTACK_PKLIVE;

// Item types for book delivery
const itemtypes = [
  { id: 1, name: "Documents", iconName: <FaTruck size="2em" /> },
  { id: 2, name: "Food", iconName: <FaTruck size="2em" /> },
  { id: 3, name: "Cakes", iconName: <FaTruck size="2em" /> },
  { id: 4, name: "Books", iconName: <FaTruck size="2em" /> },
  { id: 5, name: "Appliance", iconName: <FaTruck size="2em" /> },
  { id: 6, name: "Device", iconName: <FaTruck size="2em" /> },
  { id: 7, name: "Fashion", iconName: <FaTruck size="2em" /> },
  { id: 8, name: "Cosmetics", iconName: <FaTruck size="2em" /> },
  { id: 9, name: "Medicine", iconName: <FaTruck size="2em" /> },
  { id: 10, name: "Others", iconName: <FaTruck size="2em" /> },
];

// Delivery types
const deliverytypes = [
  { id: 1, name: "Motor" },
  { id: 2, name: "Car" },
];

// Ghana regions
const ghanaRegions = [
  { id: 1, name: "Ahafo Region" },
  { id: 2, name: "Ahsanti Region" },
  { id: 3, name: "Bono East Region" },
  { id: 4, name: "Bono Region" },
  { id: 5, name: "Central Region" },
  { id: 6, name: "Eastern Region" },
  { id: 7, name: "Greater Accra Region" },
  { id: 8, name: "North East Region" },
  { id: 9, name: "Northern Region" },
  { id: 10, name: "Oti Region" },
  { id: 11, name: "Savannah Region" },
  { id: 12, name: "Upper East Region" },
  { id: 13, name: "Upper West Region" },
  { id: 14, name: "Volta Region" },
  { id: 15, name: "Western North Region" },
  { id: 16, name: "Western Region" },
];

// Help links - general
const helpLinksGeneral = [
  {
    id: 1,
    name: "Make Report",
    link: "/book-delivery#complaintForm",
    isHash: true,
  },
  { id: 2, name: "Privacy Policy", link: "/privacy" },
  { id: 3, name: "Terms & Conditions", link: "/terms" },
  { id: 4, name: "FAQs", link: "/faqs" },
];

// Help links - prime
const helpLinksPrime = [
  { id: 1, name: "Purchasing a Prime Package", link: "/purchasing-prime" },
  { id: 2, name: "Booking With Prime", link: "/booking-prime" },
  { id: 3, name: "Prime Terms & Conditions", link: "/prime-terms" },
  { id: 4, name: "Benefits & Refund Policy", link: "/benefits-refund" },
];

// Help links - donate
const helpLinksDonate = [
  { id: 1, name: "About Apex Donate", link: "/donate-about" },
  { id: 2, name: "Guidelines", link: "/donate-guidelines" },
  { id: 3, name: "Donation Terms & Conditions", link: "/donate-terms" },
  { id: 4, name: "Legal", link: "/legal" },
];

// Item sizes
const itemSizes = [
  { id: 1, name: "Big" },
  { id: 2, name: "Medium" },
  { id: 3, name: "Large" },
];

// Schedule delivery options
const scheduleDeliveryOptions = [
  { key: "Yes", value: "true" },
  { key: "No", value: "false" },
];

// Return trip options
const returnTripOptions = [
  { key: "Yes", value: "true" },
  { key: "No", value: "false" },
];

// Multiple dropoff options
const multipleDropoffOptions = [
  { key: "Yes", value: "true" },
  { key: "No", value: "false" },
];

// Export
export {
  currSymbol,
  intl,
  baseURL,
  googleMapApiKey,
  redirectURL,
  paystackKey,
  itemtypes,
  deliverytypes,
  ghanaRegions,
  helpLinksGeneral,
  helpLinksPrime,
  helpLinksDonate,
  itemSizes,
  scheduleDeliveryOptions,
  returnTripOptions,
  multipleDropoffOptions,
};
