// Import resources
import { useEffect } from "react";
import { useDispatch } from "react-redux";

// Import custom files
import fireDB from "../config/firebase";
import {
  setDeliveryConditions,
  setDeliveryPrices,
} from "../redux/slices/navSlice";
import {
  setGeneralDetails,
  setPageAboutUs,
  setPageBenefitsRefund,
  setPageBookDelivery,
  setPageBookingPrime,
  setPageDonateAbout,
  setPageDonateGuidelines,
  setPageDonateTerms,
  setPageFAQs,
  setPageHome,
  setPageLegal,
  setPagePrimeTerms,
  setPagePrivacyPolicy,
  setPagePurchasingPrime,
  setPageTermsConditions,
} from "../redux/slices/webDetailsSlice";

// Component
function GetDatabaseContent() {
  // Define dispatch
  const dispatch = useDispatch();

  // Define state
  // const [sliders, setSliders] = useState();
  // console.log("Sliders: ", sliders);

  // Side effects
  // Select all database content
  useEffect(() => {
    // GENERAL DETAILS
    const unsubscribe = fireDB
      .collection("webdetails")
      .doc("generalDetails")
      .onSnapshot((doc) => {
        // Check if document exist
        if (doc.exists) {
          //console.log("Links: ", doc.data());
          dispatch(setGeneralDetails(doc.data()));
        } else {
          console.log("Error: No document found.");
        }
      });

    // DELIVERY CHARGES
    fireDB.collection("deliveryCharges").onSnapshot((snapshot) => {
      // Define data
      let data = [];
      // Check if document exist
      if (snapshot) {
        // Prepare data
        snapshot.docs.forEach((doc) => {
          data.push(doc.data());
        });
        // Dispatch
        dispatch(setDeliveryPrices(data));
      } else {
        console.log("Error: No document found.");
      }
    });

    // fireDB.collection("charges").onSnapshot((snapshot) => {
    //   // Check if document exist
    //   if (snapshot) {
    //     dispatch(
    //       setDeliveryPrices(
    //         snapshot.docs.map((doc) => ({
    //           id: doc.id,
    //           carPrice: doc.data().carprice,
    //           motoPrice: doc.data().motoprice,
    //         }))
    //       )
    //     );
    //   } else {
    //     console.log("Error: No document found.");
    //   }
    // });

    // CHARGE CONDITIONS
    fireDB.collection("chargeConditions").onSnapshot((snapshot) => {
      // Check if document exist
      if (snapshot) {
        dispatch(
          setDeliveryConditions(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              price: doc.data().price,
            }))
          )
        );
      } else {
        console.log("Error: No document found.");
      }
    });

    // PAGE HOME
    fireDB
      .collection("webdetails")
      .doc("pageHome")
      .onSnapshot((doc) => {
        // Check if document exist
        if (doc.exists) {
          dispatch(setPageHome(doc.data()));
        } else {
          console.log("Error: No document found.");
        }
      });

    // SLIDERS
    // fireDB
    //   .collection("webdetails")
    //   .doc("mainsliders")
    //   .collection("homepagesliders")
    //   .onSnapshot((snapshot) => {
    //     if (snapshot) {
    //       setSliders(
    //         snapshot.docs.map((doc) => ({
    //           id: doc.data().id,
    //           image: doc.data().image,
    //           caption: doc.data().caption,
    //           slug: doc.data().slug,
    //         }))
    //       );
    //     } else {
    //       console.log("Error: No document found.");
    //     }
    //   });

    // PAGE BOOK DELIVERY
    fireDB
      .collection("webdetails")
      .doc("pageBookDelivery")
      .onSnapshot((doc) => {
        // Check if document exist
        if (doc.exists) {
          dispatch(setPageBookDelivery(doc.data()));
        } else {
          console.log("Error: No document found.");
        }
      });

    // PAGE ABOUT US
    fireDB
      .collection("webdetails")
      .doc("pageAboutUs")
      .onSnapshot((doc) => {
        // Check if document exist
        if (doc.exists) {
          //console.log("About us: ", doc.data());
          dispatch(setPageAboutUs(doc.data()));
        } else {
          console.log("Error: No document found.");
        }
      });

    // PAGE BENEFITS & REFUND
    fireDB
      .collection("webdetails")
      .doc("pageBenefitsRefund")
      .onSnapshot((doc) => {
        // Check if document exist
        if (doc.exists) {
          dispatch(setPageBenefitsRefund(doc.data()));
        } else {
          console.log("Error: No document found.");
        }
      });

    // PAGE BOOKING PRIME
    fireDB
      .collection("webdetails")
      .doc("pageBookingPrime")
      .onSnapshot((doc) => {
        // Check if document exist
        if (doc.exists) {
          dispatch(setPageBookingPrime(doc.data()));
        } else {
          console.log("Error: No document found.");
        }
      });

    // PAGE DONATE ABOUT
    fireDB
      .collection("webdetails")
      .doc("pageDonateAbout")
      .onSnapshot((doc) => {
        // Check if document exist
        if (doc.exists) {
          dispatch(setPageDonateAbout(doc.data()));
        } else {
          console.log("Error: No document found.");
        }
      });

    // PAGE DONATE GUIDELINES
    fireDB
      .collection("webdetails")
      .doc("pageDonateGuidelines")
      .onSnapshot((doc) => {
        // Check if document exist
        if (doc.exists) {
          dispatch(setPageDonateGuidelines(doc.data()));
        } else {
          console.log("Error: No document found.");
        }
      });

    // PAGE DONATE TERMS
    fireDB
      .collection("webdetails")
      .doc("pageDonateTerms")
      .onSnapshot((doc) => {
        // Check if document exist
        if (doc.exists) {
          dispatch(setPageDonateTerms(doc.data()));
        } else {
          console.log("Error: No document found.");
        }
      });

    // PAGE FAQS
    fireDB
      .collection("webdetails")
      .doc("pageFAQs")
      .onSnapshot((doc) => {
        // Check if document exist
        if (doc.exists) {
          dispatch(setPageFAQs(doc.data()));
        } else {
          console.log("Error: No document found.");
        }
      });

    // PAGE LEGAL
    fireDB
      .collection("webdetails")
      .doc("pageLegal")
      .onSnapshot((doc) => {
        // Check if document exist
        if (doc.exists) {
          dispatch(setPageLegal(doc.data()));
        } else {
          console.log("Error: No document found.");
        }
      });

    // PAGE PRIME TERMS
    fireDB
      .collection("webdetails")
      .doc("pagePrimeTerms")
      .onSnapshot((doc) => {
        // Check if document exist
        if (doc.exists) {
          dispatch(setPagePrimeTerms(doc.data()));
        } else {
          console.log("Error: No document found.");
        }
      });

    // PAGE PRIVACY POLICY
    fireDB
      .collection("webdetails")
      .doc("pagePrivacyPolicy")
      .onSnapshot((doc) => {
        // Check if document exist
        if (doc.exists) {
          dispatch(setPagePrivacyPolicy(doc.data()));
        } else {
          console.log("Error: No document found.");
        }
      });

    // PAGE PURCHASING PRIME
    fireDB
      .collection("webdetails")
      .doc("pagePurchasingPrime")
      .onSnapshot((doc) => {
        // Check if document exist
        if (doc.exists) {
          dispatch(setPagePurchasingPrime(doc.data()));
        } else {
          console.log("Error: No document found.");
        }
      });

    // PAGE TERMS
    fireDB
      .collection("webdetails")
      .doc("pageTermsConditions")
      .onSnapshot((doc) => {
        // Check if document exist
        if (doc.exists) {
          dispatch(setPageTermsConditions(doc.data()));
        } else {
          console.log("Error: No document found.");
        }
      });

    // Clean up
    return unsubscribe;
  }, [dispatch]);

  // Return
  return null;
}

// Export
export default GetDatabaseContent;
