// Import resources
import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const resetInitialState = { returned: [] };
const initialState = {
  pickup: null,
  dropoff: null,
  travelTimeInformation: null,
  travelTimeKM: null,
  travelTimePrice: 0,
  travelTimeSlot: 0,
  bookingConditions: null,
  home: null,
  work: null,
  deliveryPrices: null,
  deliveryPriceFinal: null,
  deliveryConditions: null,
  selectedDeliveryType: null,
};

// Create nav slice
export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setPickup: (state, action) => {
      state.pickup = action.payload;
    },
    setDropoff: (state, action) => {
      state.dropoff = action.payload;
    },
    setTravelTimeInformation: (state, action) => {
      state.travelTimeInformation = action.payload;
    },
    setTravelTimeKM: (state, action) => {
      state.travelTimeKM = action.payload;
    },
    setTravelTimePrice: (state, action) => {
      state.travelTimePrice = action.payload;
    },
    setTravelTimeSlot: (state, action) => {
      state.travelTimeSlot = action.payload;
    },
    setBookingConditions: (state, action) => {
      state.bookingConditions = action.payload;
    },
    setHome: (state, action) => {
      state.home = action.payload;
    },
    setWork: (state, action) => {
      state.work = action.payload;
    },
    setDeliveryPrices: (state, action) => {
      state.deliveryPrices = action.payload;
    },
    setDeliveryPriceFinal: (state, action) => {
      state.deliveryPriceFinal = action.payload;
    },
    setDeliveryConditions: (state, action) => {
      state.deliveryConditions = action.payload;
    },
    setSelectedDeliveryType: (state, action) => {
      state.selectedDeliveryType = action.payload;
    },
    setResetNavSlice: () => resetInitialState,
  },
});

// Define action creators for each reducer
export const {
  setPickup,
  setDropoff,
  setTravelTimeInformation,
  setTravelTimeKM,
  setTravelTimePrice,
  setTravelTimeSlot,
  setBookingConditions,
  setHome,
  setWork,
  setResetNavSlice,
  setDeliveryPrices,
  setDeliveryPriceFinal,
  setDeliveryConditions,
  setSelectedDeliveryType,
} = navSlice.actions;

// Define selectors for each action
// Selectors are used to select the current state
export const selectPickup = (state) => state.nav.pickup;
export const selectDropoff = (state) => state.nav.dropoff;
export const selectTravelTimeInformation = (state) =>
  state.nav.travelTimeInformation;
export const selectTravelTimeKM = (state) => state.nav.travelTimeKM;
export const selectTravelTimePrice = (state) => state.nav.travelTimePrice;
export const selectTravelTimeSlot = (state) => state.nav.travelTimeSlot;
export const selectBookingConditions = (state) => state.nav.bookingConditions;
export const selectHome = (state) => state.nav.home;
export const selectWork = (state) => state.nav.work;
export const selectDeliveryPrices = (state) => state.nav.deliveryPrices;
export const selectDeliveryPriceFinal = (state) => state.nav.deliveryPriceFinal;
export const selectDeliveryConditions = (state) => state.nav.deliveryConditions;
export const selectSelectedDeliveryType = (state) =>
  state.nav.selectedDeliveryType;
//export const selectResetNavSlice = (state) => state.nav.setResetNavSlice;

// Export nav slice as default
export default navSlice.reducer;
