// Import resources
import React from "react";
import CustomSpinner from "./CustomSpinner";

// Component
function CustomPageSpinner() {
  // Return
  return (
    <div className="load-spinner">
      <CustomSpinner animation="grow" size="lg" />
    </div>
  );
}

// Export
export default CustomPageSpinner;
