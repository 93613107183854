// Import resources
import React from "react";
import {
  ButtonGroup,
  Container,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

// Import custom files
import CustomButton from "./CustomButton";
import { selectUser } from "../redux/slices/userSlice";
import { selectGeneralDetails } from "../redux/slices/webDetailsSlice";
import { HashLink } from "react-router-hash-link";

// Component
function Header() {
  // Define user
  const user = useSelector(selectUser);

  // Select general details
  const generalDetails = useSelector(selectGeneralDetails);

  // Return
  return (
    <>
      <Navbar bg="dark" expand="lg" variant="dark">
        <Container>
          {/** Brand */}
          <Navbar.Brand as={Link} to="/">
            <img src={generalDetails?.companyLogo} alt="logo" />
            {user && (
              <span className="small text-truncate">Hi, {user.name}</span>
            )}
          </Navbar.Brand>

          {/** Toggle button */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          {/** Collapse */}
          <Navbar.Collapse id="basic-navbar-nav">
            {/** Links */}
            <Nav className="ms-auto my-nav">
              {/** Home */}
              <Nav.Link as={NavLink} exact to="/" activeClassName="active">
                Home
              </Nav.Link>

              {/** About */}
              <Nav.Link as={NavLink} exact to="/about" activeClassName="active">
                About
              </Nav.Link>

              {/** Services */}
              <NavDropdown
                title="Services"
                id="basic-nav-dropdown"
                className="my-dropdown"
              >
                {/** Apex delivery */}
                <NavDropdown.Item
                  as={NavLink}
                  to="/book-delivery"
                  activeClassName="active"
                >
                  Book Delivery
                </NavDropdown.Item>

                {/** Apex prime */}
                <NavDropdown.Item as={NavLink} to="/subscribe">
                  Subscribe to Apex Prime
                </NavDropdown.Item>

                {/** Apex donate */}
                <NavDropdown.Item as={NavLink} to="/donate">
                  Donate
                </NavDropdown.Item>
              </NavDropdown>

              {/** Get help */}
              <ButtonGroup>
                <CustomButton as={Link} to="/help-center" variant="success">
                  Get Help
                </CustomButton>

                {/** Price calculator */}
                <CustomButton
                  as={HashLink}
                  to="/#calculatorDiv"
                  variant="danger"
                  className="text-white"
                >
                  Calculate Price
                </CustomButton>

                {/** Login or Signup button */}
                {user ? (
                  <Nav.Link as={NavLink} to="/dashboard" className="ps-3">
                    My Account
                  </Nav.Link>
                ) : (
                  <CustomButton as={Link} to="/login">
                    Login or Register
                  </CustomButton>
                )}
              </ButtonGroup>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

// Export
export default Header;
