// Import resources
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Define firebase config - KLINCODER
const firebaseConfig = {
  apiKey: "AIzaSyApOzvKRie9bVE9pYx9Zuiww6XtDk_uprU",
  authDomain: "apex-delivery-be224.firebaseapp.com",
  databaseURL: "https://apex-delivery-be224.firebaseio.com",
  projectId: "apex-delivery-be224",
  storageBucket: "apex-delivery-be224.appspot.com",
  messagingSenderId: "465581136089",
  appId: "1:465581136089:web:8a0d91b3b8f8cdb6a246c8",
  measurementId: "G-CNCJ8XVYST",
};

// Initialize firebase
const app = firebase.initializeApp(firebaseConfig);

// Set firestore database
const fireDB = app.firestore();

// Set auth
const fireAuth = firebase.auth();

// Export
export { fireAuth };
export default fireDB;
