// Import resources
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTravelTimeInformation,
  selectBookingConditions,
  setTravelTimeSlot,
} from "../redux/slices/navSlice";

// Component
function CalculateDeliverySlot() {
  //  Select travelTimeInformation and bookingConditions
  const travelTime = useSelector(selectTravelTimeInformation);
  const bookingConditions = useSelector(selectBookingConditions);

  // Define state for travalTimeKM and slotsToDeduct
  // Calculate kilometers
  const travelTimeKM = (travelTime?.distance.value / 1000).toFixed(0);
  // const [slotsToDeduct, setSlotsToDeduct] = useState(0);
  let slotsToDeduct = 0;

  // Set dispatch
  const dispatch = useDispatch();

  /*******************************************
  Calculte slotsToDeduct based on travelTimeKM
  ******************************************/
  useEffect(() => {
    // Check if travelTimeKM is ready
    if (!travelTimeKM) return;

    // Calculate travel time price based on kilometers
    if (travelTimeKM <= 27) {
      // setSlotsToDeduct(1);
      // eslint-disable-next-line
      slotsToDeduct = 1;
    } else if (travelTimeKM > 27) {
      // setSlotsToDeduct(2);
      slotsToDeduct = 2;
    }

    // Check bookingConditions
    // returnTrip
    if (bookingConditions?.returnTrip === "Yes") {
      // setSlotsToDeduct(slotsToDeduct * 2);
      slotsToDeduct *= 2;
    }

    // Delivery type
    if (bookingConditions?.deliveryType === "Car") {
      // setSlotsToDeduct(...(slotsToDeduct + 18));
      slotsToDeduct += 1;
    }

    // Set travel time slots to global store
    dispatch(setTravelTimeSlot(slotsToDeduct));

    // Debug
    // console.log(
    //   "KM: ",
    //   travelTimeKM,
    //   "Price:",
    //   price,
    //   " - Booking Conditions",
    //   bookingConditions
    // );
  }, [dispatch, travelTimeKM, bookingConditions]);

  // Return
  return null;
}

// Export
export default CalculateDeliverySlot;
