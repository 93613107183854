// Import resources
import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

// Import custom files
import { selectUser } from "../redux/slices/userSlice";

// Component
function ProtectedRoute({ component: Component, children, ...rest }) {
  // Select user
  const user = useSelector(selectUser);

  // Return
  return (
    <>
      {user ? <Route {...rest}>{children}</Route> : <Redirect to="/login" />}

      {/* <Route
        {...rest}
        render={({ location, ...props }) => {
          // If user is logged in
          return user ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          );
        }}
      /> */}
    </>
  );
}

// Export
export default ProtectedRoute;
