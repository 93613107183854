// Import resources
import { useEffect } from "react";
import axios from "axios";
import "intl";
import "intl/locale-data/jsonp/en-GH";
import { useDispatch, useSelector } from "react-redux";

// Import custom files
import { baseURL, googleMapApiKey } from "../config/data";
import { handleDeliveryPrice } from "../config/appConfig";
import {
  selectPickup,
  selectDropoff,
  selectTravelTimeInformation,
  selectBookingConditions,
  setTravelTimeInformation,
  setTravelTimePrice,
  selectDeliveryConditions,
  selectSelectedDeliveryType,
  selectDeliveryPrices,
  selectTravelTimePrice,
  setDeliveryPriceFinal,
} from "../redux/slices/navSlice";

// Component
function CalculateDeliveryFee() {
  // Select pickup and droppoff from store
  const pickup = useSelector(selectPickup);
  const dropoff = useSelector(selectDropoff);

  //  Select state from store
  const travelTime = useSelector(selectTravelTimeInformation);
  const bookingConditions = useSelector(selectBookingConditions);
  const deliveryPriceList = useSelector(selectDeliveryPrices);
  const travelTimePrice = useSelector(selectTravelTimePrice);
  const deliveryConditions = useSelector(selectDeliveryConditions);
  const selectedDeliveryType = useSelector(selectSelectedDeliveryType);

  // Set dispatch
  const dispatch = useDispatch();

  // Debug
  //console.log("calcDeliveryFee: ", deliveryPrices);

  // SIDE EFFECTS
  // FETCH TRAVEL TIME FROM GOOGLE
  useEffect(() => {
    // If no locations, return
    if (!pickup || !dropoff) return;
    // Get travel time
    const getTravelTime = async () => {
      await axios
        .get(
          `${baseURL}/calculate-delivery?units=metric&origins=place_id:${pickup.location}&destinations=place_id:${dropoff.location}&key=${googleMapApiKey}`
        )
        //.then((res) => res.json())
        .then((response) => {
          // Define apiRes
          const apiRes = response.data.rows[0].elements[0];
          // Dispatch travel time information
          dispatch(setTravelTimeInformation(apiRes));
          //console.log("calcDeliverfyFee: ", apiRes);
        })
        .catch((error) => {
          console.log("Error: ", error.message);
        });
    };
    // Call fxn
    getTravelTime();
    // Convert travelTime to kilometers
    const travelTimeToKM = Math.round(travelTime?.distance.value / 1000);
    // Calculate travel time price in kilometer with deliveryPriceList
    const calcTravelTimePrice = handleDeliveryPrice(
      deliveryPriceList,
      travelTimeToKM
    );
    // Dispatch
    dispatch(setTravelTimePrice(calcTravelTimePrice));
    // Debug
    // console.log("travelTimeToKM: ", travelTimeToKM);
    // console.log("calcTravelTimePrice: ", calcTravelTimePrice);
  }, [
    dispatch,
    pickup,
    dropoff,
    travelTime?.distance.value,
    deliveryPriceList,
  ]);

  // SIDE EFFECTS
  // CALCULATE DELIVERY PRICE FINAL
  useEffect(() => {
    // If no travelTimePrice, return
    if (!travelTimePrice) return;
    // Debug
    //console.log("DeliveryKM, ", travelTimeKM);
    // Get database price
    // Define final price
    // Final price is based on travelTimePrice + selectedDeliveryType
    //let getPriceID = deliveryPrices?.find(({ id }) => id === travelTimeKM);

    // Define selectedDeliveryTypePrice
    const selectedDeliveryTypePrice =
      selectedDeliveryType === "car"
        ? travelTimePrice?.car
        : travelTimePrice?.motor;

    // Define urgentPrice based on deliveryConditions from global state
    const urgentPrice = deliveryConditions?.find(({ id }) => id === "urgent");

    // Define returnTripPrice based on deliveryConditions from global state
    const returnTripPrice = deliveryConditions?.find(
      ({ id }) => id === "returnTrip"
    );

    // Debug
    //console.log("calcDeliveryUseEffect: ", deliveryConditions);

    // Define carExtraPrice based on deliveryConditions from global state
    const carExtraPrice = deliveryConditions?.find(({ id }) => id === "car");

    // Define finalPrice
    let finalPrice = selectedDeliveryTypePrice;

    // Check if booking conditions delivery speed === Urgent
    if (bookingConditions?.speed === "Urgent") {
      finalPrice += urgentPrice?.price; //extraPriceUrgent.price;
    }

    // Check if booking conditions returnTrip === Yes
    if (bookingConditions?.returnTrip === "Yes") {
      finalPrice *= returnTripPrice?.price; //extraPriceReturnTrip.price;
    }

    // Check if booking conditions delivery type = Car
    if (bookingConditions?.deliveryType === "Car") {
      finalPrice += carExtraPrice?.price; //extraPriceCar.price;
    }

    // Debug
    //console.log("Debug calcDeliveryPriceFinal: ", selectedDeliveryTypePrice);

    // Dispatch travel time price
    dispatch(setDeliveryPriceFinal(finalPrice));
  }, [
    dispatch,
    bookingConditions,
    deliveryConditions,
    selectedDeliveryType,
    travelTimePrice,
  ]);

  // Return component
  return null;
}

// Export
export default CalculateDeliveryFee;
