// Import resources
import React from "react";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";

// Component
function TopMenu() {
  // Return
  return (
    <div className="top-menu text-center text-white">
      <ul className="list-inline">
        {/** Phone */}
        <li className="list-inline-item">
          <a href="tel:0557311577">
            <FaPhoneAlt /> 0557311577
          </a>
        </li>

        {/** Email */}
        <li className="list-inline-item">
          <a href="mailto:support@apexdelivery.com">
            <FaEnvelope /> Email Support
          </a>
        </li>
      </ul>
    </div>
  );
}

// Export
export default TopMenu;
