// Import resources
import React, { useEffect, useState } from "react";
import { FaQuestion } from "react-icons/fa";
import { Link } from "react-router-dom";

// Export
export default function FloatingHelpButton() {
  // Define button visibility state
  const [helpVisible, setHelpVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setHelpVisible(true);
    } else {
      setHelpVisible(false);
    }
  };

  // Load this effect once on page load
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    // Unmount
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  // Return
  return (
    <>
      {helpVisible && (
        <Link to="/help-center" className="floating-help-button">
          <FaQuestion />
        </Link>
      )}
    </>
  );
}
