// Import resources
import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  currentSidebarState: false,
};

// Create slice
export const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    setCurrentSidebarState: (state, action) => {
      state.currentSidebarState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
// Set action creators
export const { setCurrentSidebarState } = cmsSlice.actions;

// Set selectors
export const selectCurrentSidebarState = (state) =>
  state.cms.currentSidebarState;

// Export
export default cmsSlice.reducer;
