// Import resources
import React from "react";

// Component
function PageCardHeader({
  children,
  cardClass,
  cardClassHeader,
  cardClassTitle,
  cardTitle,
}) {
  // Return
  return (
    <div className={cardClass}>
      <div className={cardClassHeader}>
        <h3 className={cardClassTitle}>{cardTitle}</h3>
      </div>
      <div className="card-body">{children}</div>
    </div>
  );
}

// Export
export default PageCardHeader;
