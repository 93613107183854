// Import resources
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { store } from "./redux/store";
import { Provider as ReduxProvider } from "react-redux";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

// Import bootstrap css and js
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// AlertProvider options
const optionsAlertProvider = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

// Render
ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <AlertProvider template={AlertTemplate} {...optionsAlertProvider}>
        <App />
      </AlertProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
