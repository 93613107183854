// Import resources
import React, { Suspense, lazy } from "react";
import { Route } from "react-router-dom";

// Import custom files
import CustomPageSpinner from "../components/CustomPageSpinner";
import ProtectedRoute from "../components/ProtectedRoute";

// Component
const useCustomRoutes = () => {
  // Implement component lazy loading
  //const  = lazy(() => import("../pages/"));
  const Home = lazy(() => import("../pages/Home"));
  const About = lazy(() => import("../pages/About"));
  const Donate = lazy(() => import("../pages/Donate"));
  const BookDelivery = lazy(() => import("../pages/BookDelivery"));
  const Subscribe = lazy(() => import("../pages/Subscribe"));
  const FAQs = lazy(() => import("../pages/FAQs"));
  const DonateCash = lazy(() => import("../pages/DonateCash"));
  const DonateItems = lazy(() => import("../pages/DonateItems"));
  const DonateRaiseFunds = lazy(() => import("../pages/DonateRaiseFunds"));
  const DonateProjectDetails = lazy(() =>
    import("../pages/DonateProjectDetails")
  );
  const DonatePay = lazy(() => import("../pages/DonatePay"));
  const HelpCenter = lazy(() => import("../pages/HelpCenter"));
  const PrimePersonal = lazy(() => import("../pages/PrimePersonal"));
  const PrimePersonalDetails = lazy(() =>
    import("../pages/PrimePersonalDetails")
  );
  const PrimeBusiness = lazy(() => import("../pages/PrimeBusiness"));
  const PrimeBusinessDetails = lazy(() =>
    import("../pages/PrimeBusinessDetails")
  );
  const Privacy = lazy(() => import("../pages/Privacy"));
  const Terms = lazy(() => import("../pages/Terms"));
  const PasswordRecovery = lazy(() => import("../pages/PasswordRecovery"));
  const PasswordReset = lazy(() => import("../pages/PasswordReset"));
  const MakeReport = lazy(() => import("../pages/MakeReport"));
  const PurchasingPrime = lazy(() => import("../pages/PurchasingPrime"));
  const BookingPrime = lazy(() => import("../pages/BookingPrime"));
  const PrimeTerms = lazy(() => import("../pages/PrimeTerms"));
  const BenefitsRefund = lazy(() => import("../pages/BenefitsRefund"));
  const DonateAbout = lazy(() => import("../pages/DonateAbout"));
  const DonateGuidelines = lazy(() => import("../pages/DonateGuidelines"));
  const DonateTerms = lazy(() => import("../pages/DonateTerms"));
  const Legal = lazy(() => import("../pages/Legal"));
  const Test = lazy(() => import("../pages/Test"));

  // CMS
  const Subscriptions = lazy(() => import("../cms/Subscriptions"));
  const DashboardUser = lazy(() => import("../cms/DashboardUser"));
  const Deliveries = lazy(() => import("../cms/Deliveries"));
  const Dashboard = lazy(() => import("../cms/Dashboard"));
  const Settings = lazy(() => import("../cms/Settings"));

  // Define routesArr
  const lazyRoutesArr = [
    // {
    //   path: "/",
    //   component: < />,
    //   authRequired: false,
    // },
    {
      path: "/",
      component: <Home />,
      authRequired: false,
    },
    {
      path: "/about",
      component: <About />,
      authRequired: false,
    },
    {
      path: "/donate",
      component: <Donate />,
      authRequired: false,
    },

    {
      path: "/faqs",
      component: <FAQs />,
      authRequired: false,
    },
    {
      path: "/donate-cash",
      component: <DonateCash />,
      authRequired: false,
    },
    {
      path: "/donate-items",
      component: <DonateItems />,
      authRequired: false,
    },
    {
      path: "/donate-raise-funds",
      component: <DonateRaiseFunds />,
      authRequired: false,
    },
    {
      path: "/test",
      component: <Test />,
      authRequired: false,
    },
    {
      path: "/password-recovery",
      component: <PasswordRecovery />,
      authRequired: false,
    },
    {
      path: "/password-reset",
      component: <PasswordReset />,
      authRequired: false,
    },
    {
      path: "/privacy",
      component: <Privacy />,
      authRequired: false,
    },
    {
      path: "/terms",
      component: <Terms />,
      authRequired: false,
    },
    {
      path: "/donate-guidelines",
      component: <DonateGuidelines />,
      authRequired: false,
    },
    {
      path: "/donate-pay/:projID",
      component: <DonatePay />,
      authRequired: false,
    },
    {
      path: "/help-center",
      component: <HelpCenter />,
      authRequired: false,
    },
    {
      path: "/make-report",
      component: <MakeReport />,
      authRequired: false,
    },
    {
      path: "/donate-about",
      component: <DonateAbout />,
      authRequired: false,
    },
    {
      path: "/purchasing-prime",
      component: <PurchasingPrime />,
      authRequired: false,
    },
    {
      path: "/booking-prime",
      component: <BookingPrime />,
      authRequired: false,
    },
    {
      path: "/prime-terms",
      component: <PrimeTerms />,
      authRequired: false,
    },
    {
      path: "/benefits-refund",
      component: <BenefitsRefund />,
      authRequired: false,
    },
    {
      path: "/donate-terms",
      component: <DonateTerms />,
      authRequired: false,
    },
    {
      path: "/legal",
      component: <Legal />,
      authRequired: false,
    },
    {
      path: "/donate-project-details/:projID",
      component: <DonateProjectDetails />,
      authRequired: false,
    },
    {
      // PROTECTED
      path: "/book-delivery",
      component: <BookDelivery />,
      authRequired: true,
    },
    {
      path: "/subscribe",
      component: <Subscribe />,
      authRequired: true,
    },
    {
      path: "/prime-personal",
      component: <PrimePersonal />,
      authRequired: true,
    },
    {
      path: "/prime-business",
      component: <PrimeBusiness />,
      authRequired: true,
    },
    {
      path: "/settings",
      component: <Settings />,
      authRequired: true,
    },
    {
      path: "/dashboard",
      component: <Dashboard />,
      authRequired: true,
    },
    {
      path: "/dashboard-user",
      component: <DashboardUser />,
      authRequired: true,
    },
    {
      path: "/subscriptions",
      component: <Subscriptions />,
      authRequired: true,
    },
    {
      path: "/deliveries",
      component: <Deliveries />,
      authRequired: true,
    },
    {
      path: "/prime-personal-details/:primeID",
      component: <PrimePersonalDetails />,
      authRequired: true,
    },
    {
      path: "/prime-business-details/:primeID",
      component: <PrimeBusinessDetails />,
      authRequired: true,
    },
  ];

  // Loop routes
  const lazyRoutes = lazyRoutesArr.map(
    ({ path, component, authRequired }, index) => {
      // If authRequired
      if (authRequired) {
        return (
          <ProtectedRoute exact path={path} key={index}>
            <Suspense fallback={<CustomPageSpinner />}>{component}</Suspense>
          </ProtectedRoute>
        );
      } else {
        return (
          <Route exact path={path} key={index}>
            <Suspense fallback={<CustomPageSpinner />}>{component}</Suspense>
          </Route>
        );
      }
    }
  );

  // Return component
  return { lazyRoutes };
};

export default useCustomRoutes;
