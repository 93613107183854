// Import resources
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import navReducer from "./slices/navSlice";
import webDetailsReducer from "./slices/webDetailsSlice";
import cmsReducer from "./slices/cmsSlice";

// Export
// Add different reducers to store
export const store = configureStore({
  reducer: {
    user: userReducer,
    nav: navReducer,
    webDetails: webDetailsReducer,
    cms: cmsReducer,
  },
});
