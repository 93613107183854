// Import resources
import React from "react";
import { useField, ErrorMessage } from "formik";

// Component
function CustomTextField({
  divClass,
  label,
  isGroup,
  isGroupText,
  formHelperText,
  ...props
}) {
  // Define use field hook
  const [field, meta] = useField(props);

  // Return
  return (
    <div className={divClass}>
      {/** If isGroup */}
      {isGroup ? (
        <span className="input-group-text">{isGroupText}</span>
      ) : (
        <label htmlFor={field.name} className="form-label fw-bold small">
          {label}
        </label>
      )}

      {/** Input */}
      <input
        className={`form-control shadow-none ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}
      />

      {/** Form helper text */}
      {formHelperText && (
        <div className="form-text w-100">{formHelperText}</div>
      )}

      {/** Error message */}
      <ErrorMessage name={field.name} component="div" className="error" />
    </div>
  );
}

// Export
export default CustomTextField;
