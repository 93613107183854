// Import resources
import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fireDB from "./config/firebase";
import { fireAuth } from "./config/firebase";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setLogin, setLogout } from "./redux/slices/userSlice";
import useCustomRoutes from "./hooks/useCustomRoutes";

// Import custom files
import Login from "./pages/Login";
import GetDatabaseContent from "./components/GetDatabaseContent";
import CalculateDeliverySlot from "./components/CalculateDeliverySlot";
import CalculateDeliveryFee from "./components/CalculateDeliveryFee";
import FloatingHelpButton from "./components/FloatingHelpButton";
import FloatingScrollButton from "./components/FloatingScrollButton";

// Component function
function App() {
  // Select logged in user
  const user = useSelector(selectUser);

  // Define varaible to dispatch actions to store
  const dispatch = useDispatch();

  // Define lazy routes
  const { lazyRoutes } = useCustomRoutes();

  // Debug
  //console.log(pageLoadingState);

  // Side effects
  useEffect(() => {
    // Listen to logged in user and persist
    // To avoid always logging in
    const unsubscribe = fireAuth.onAuthStateChanged((userAuth) => {
      // Debug
      //console.log("authUser: ", userAuth);

      // If user is logged in
      if (userAuth?.emailVerified) {
        // Select user details from DB
        fireDB
          .collection("users")
          .doc(userAuth.uid)
          .onSnapshot((doc) => {
            if (doc.exists) {
              // Dispatch login action and payload
              dispatch(
                setLogin({
                  uid: userAuth.uid,
                  role: doc.data().role,
                  email: userAuth.email,
                  name: doc.data().name,
                  phone: doc.data().phone,
                  home: doc.data().homeaddressname,
                  homeID: doc.data().homeaddressid,
                  work: doc.data().workaddressname,
                  workID: doc.data().workaddressid,
                  isSubscribed: doc.data().subscribed,
                  isSubscribedType: doc.data().subscription,
                  isSubscribedSlot: doc.data().count,
                })
              );
            }
          });
      } else {
        // Dispatch logout action
        dispatch(setLogout());
      }
    });

    // Cleanup
    return unsubscribe;
  }, [dispatch]);

  // Return
  return (
    <>
      {/* Calculate delivery fee and slot */}
      <CalculateDeliveryFee />
      <CalculateDeliverySlot />

      {/** Get all database content */}
      <GetDatabaseContent />

      {/* Define browser router */}
      <BrowserRouter>
        {/** Switch */}
        <Switch>
          {/** First paint routes */}
          <Route path="/login">{user ? <Redirect to="/" /> : <Login />}</Route>

          {/** Lazy routes */}
          {lazyRoutes}
        </Switch>

        {/** Floating Buttons */}
        <FloatingHelpButton />
        <FloatingScrollButton />
      </BrowserRouter>
    </>
  );
}

// Export
export default App;
