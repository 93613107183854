// Import resources
import React from "react";

// Import custom files
import PageSection from "../components/PageSection";
import PageCol from "../components/PageCol";
import PageCardHeader from "../components/PageCardHeader";
import FormLogin from "../components/FormLogin";
import PageContent from "../components/PageContent";

// Component
function Login() {
  // Return
  return (
    <PageContent>
      {/** SECTION 1 */}
      <PageSection sectionClass="section-margin gap-bottom">
        <PageCol colClass="col-md-6 offset-md-3">
          <PageCardHeader
            cardClass="card shadow"
            cardClassHeader="card-header"
            cardClassTitle="card-title"
            cardTitle="Login"
          >
            {/** Login form */}
            <FormLogin />
          </PageCardHeader>
        </PageCol>
      </PageSection>
    </PageContent>
  );
}

// Export
export default Login;
