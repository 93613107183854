// Import resources
import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  userDetails: null,
  userDeliveries: null,
  userSubscriptions: null,
};

// Create slice
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.userDetails = action.payload;
    },
    setLogout: (state) => {
      state.userDetails = null;
    },
    setUserDeliveries: (state, action) => {
      state.userDeliveries = action.payload;
    },
    setUserSubscriptions: (state, action) => {
      state.userSubscriptions = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
// Set action creators
export const { setLogin, setLogout, setUserDeliveries, setUserSubscriptions } =
  userSlice.actions;

// Set selectors
// User details
export const selectUser = (state) => state.user.userDetails;
// User deliveries
export const selectUserDeliveries = (state) => state.user.userDeliveries;
// User subscriptions
export const selectUserSubscriptions = (state) => state.user.userSubscriptions;

// Export
export default userSlice.reducer;
